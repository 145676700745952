<template>
  <div>
    <v-container>
      <v-row style="margin-bottom: 100px">
        <v-col cols="12" md="8">
          <v-card flat class="mt-3">
            <div class="cardHeader">Personal Details</div>
            <div class="pa-3">
              <v-row>
                <v-col cols="12" md="6">
                  <FieldValue
                    Text="First Name"
                    :value="appData.fName"
                  ></FieldValue>
                </v-col>

                <v-col cols="12" md="6">
                  <FieldValue
                    Text="Last Name"
                    :value="appData.lName"
                  ></FieldValue>
                </v-col>

               <!--  <v-col cols="12" md="4">
                  <FieldValue
                    Text="Father/Guardian Name"
                    :value="appData.fatherName"
                  ></FieldValue>
                </v-col> -->
              </v-row>

              <v-divider class="mt-2 mb-2"></v-divider>
              <v-row>
                 <!-- <v-col cols="12" md="6">
                  <FieldValue
                    Text="Gender"
                    :value="appData.gender"
                  ></FieldValue>
                </v-col> -->
                <!-- <v-col cols="12" md="3">
                  <FieldValue Text="DOB" :value="appData.dob"></FieldValue>
                </v-col>
                <v-col cols="12" md="4">
                  <FieldValue Text="Email" :value="appData.email"></FieldValue>
                </v-col>  -->
                <v-col cols="12" md="6">
                  <FieldValue
                    Text="Mobile"
                    :value="appData.mobile"
                  ></FieldValue>
                </v-col>
              </v-row>
            </div>
          </v-card>

           <v-card flat class="mt-3">
            <div class="cardHeader">Address</div>
            <div class="pa-3">
              <v-row>
                <v-col cols="12" md="6">
                  <FieldValue
                    Text="Address 1"
                    :value="appData.addLine1"
                  ></FieldValue>
                </v-col>
                <v-col cols="12" md="6">
                  <FieldValue
                    Text="Address 2"
                    :value="appData.addLine2"
                  ></FieldValue>
                </v-col>
              </v-row>

              <v-divider class="mt-2 mb-2"></v-divider>

              <v-row>
                <v-col cols="12" md="3">
                  <FieldValue
                    Text="Country"
                    :value="appData.country"
                  ></FieldValue>
                </v-col>
                <v-col cols="12" md="3">
                  <FieldValue
                    Text="Parish/State/County"
                    :value="appData.state"
                  ></FieldValue>
                </v-col>
                <v-col cols="12" md="3">
                  <FieldValue
                    Text="Town/City"
                    :value="appData.city"
                  ></FieldValue>
                </v-col>
                <v-col cols="12" md="3">
                  <FieldValue
                    Text="Postal/Zip code"
                    :value="appData.pinCode"
                  ></FieldValue>
                </v-col>
              </v-row>
            </div>
          </v-card> 

          <v-card flat class="mt-3">
            <div class="cardHeader">Identification</div>
            <div class="pa-3">
              <v-row>
                 <v-col cols="12" md="4">
                  <FieldValue
                    Text="Nationality"
                    :value="appData.nationality"
                  ></FieldValue>
                </v-col>
                <v-col cols="12" md="4">
                  <FieldValue
                    Text="Id Proof"
                    :value="appData.idProofType"
                  ></FieldValue>
                </v-col>

                <v-col cols="12" md="4">
                  <FieldValue
                    Text="Id Proof Number"
                    :value="appData.idProofNumber"
                  ></FieldValue>
                </v-col>
              </v-row>
            </div>
          </v-card>


 <v-card flat class="mt-3">
            <div class="cardHeader">Event</div>
            <div class="pa-3">
              <v-row>
 <v-col cols="12" md="6" v-if="appData.setup==1">
                  <FieldValue
                    Text="Previous Event Type"
                    value="Setup"
                  ></FieldValue>
                </v-col>

                 <v-col cols="12" md="4" v-if="appData.wedding==1">
                  <FieldValue
                    Text="Current Event Type"
                    value="Event"
                  ></FieldValue>
                </v-col>

                

                <v-col cols="12" md="4">
                  <v-menu
                    ref="menu2"
                    v-model="menu2"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="appData.validTo"
                        outlined
                        dense
                        hide-details
                        label="Valid Till *"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      ref="picker"
                      v-model="appData.validTo"
                      :min="from_min"
                        max="2024-07-15"
                      @change="savedob"
                    ></v-date-picker>
                  </v-menu>
                </v-col>

                <!-- validTo -->

                <v-col cols="12" md="4" v-if="appData.wedding==1">
                  <!-- <FieldValue
                    Text="Access"
                    :value="appData.access"
                  ></FieldValue> -->

                  <v-select
                    v-model="appData.access"
                    :items="['RIL - FOH', 'RIL - BOH', 'ARTIST','VENDOR - FOH', 'VENDOR - BOH']"
                    label="Select Zone *"
                    hide-details
                    outlined
                    dense>
                    </v-select>
                </v-col>
                 <!-- v-if="eventType != 'SETUP'"
                    :rules="[rules.req]" -->
                    

               
                

              <!-- :items="['FOH', 'BOH', 'ARTIST - FOH', 'ARTIST - BOH']"  -->


                
              </v-row>
            </div>
          </v-card>

           <v-card flat class="mt-3" v-if="appData.wedding==1">
            <div class="cardHeader">Event Date</div>
            <div class="pa-3">
              <v-row>
                 <v-col cols="12" md="2" v-if="appData.wedding==1">
                  <v-checkbox  v-model="appData.sangeet" label="5 July"></v-checkbox>
                 
                </v-col>
                 <v-col cols="12" md="2" v-if="appData.wedding==1">
                  <v-checkbox v-model="appData.wedding12" label="12 July"></v-checkbox>
                 
                </v-col>
                 <v-col cols="12" md="2" v-if="appData.wedding==1">
                  <v-checkbox v-model="appData.wedding13" label="13 July"></v-checkbox>
                 
                </v-col>
                 <v-col cols="12" md="2" v-if="appData.wedding==1">
                  <v-checkbox v-model="appData.wedding14" label="14 July"></v-checkbox>
                 
                </v-col>

                <v-col cols="12" md="2" v-if="appData.wedding==1">
                  <v-checkbox v-model="appData.wedding15" label="15 July"></v-checkbox>
                 
                </v-col>

              </v-row>
            </div>
           </v-card>


          <v-card flat class="mt-3">
            <div class="cardHeader">Accreditation Details</div>
            <div class="pa-3">
              <v-row>
                <!-- <v-col cols="12" md="12">
                  <FieldValue
                    Text="Company"
                    :value="appData.company_name"
                  ></FieldValue>
                </v-col> -->

                <!-- <v-col cols="12" md="6">
                  <FieldValue Text="Unique Id" :value="appData.mName"></FieldValue>
                </v-col> -->

                

               

                <v-col cols="12" md="6">
                  <!-- <FieldValue
                        Text="Company type"
                        :value="appData.companytype_name"
                      ></FieldValue> -->

                  <v-select
                    label="Select Category Type"
                    v-model="appData.companytype_id"
                    :items="CompanyType"
                    item-text="companytype_name"
                    item-value="companytype_id"
                    dense
                    outlined
                    hide-details
                    @change="btnbindjob"
                  ></v-select>
                </v-col>
                <v-col cols="12" md="6">
                  <!-- <FieldValue
                        Text="Job type"
                        :value="appData.jobtype_name"
                      ></FieldValue> -->

                  <v-select
                    label="Select Job Type"
                    v-model="appData.jobtype_id"
                    :items="JobType"
                    item-text="jobtype_name"
                    item-value="jobtype_id"
                    dense
                    outlined
                    hide-details
                  ></v-select>
                </v-col>

                <v-col cols="12" md="12">
                  <v-autocomplete
                    v-model="appData.zone"
                    outlined
                    :items="Stand"
                    item-text="stand_name"
                    item-value="stand_id"
                    label="Select Stand/Zone"
                    hide-details
                    attach
                    chips
                    small-chips
                    multiple
                  ></v-autocomplete>
                </v-col>

               
              </v-row>
            </div>
          </v-card>

<div v-if="this.$store.state.eventInfo.UType != 'MediaAdmin'">
            <v-card v-if="appstatus != '7'" flat class="mt-3">
           
                  <v-card-actions class="pa-3">
                    <v-spacer></v-spacer>
                    <v-btn color="success" @click="btnsaveonly"
                      >Save Application</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
               
          </v-card>
          </div>

          <!-- <v-card flat class="mt-3">
            <div class="cardHeader">Covid 19 Vaccination Details</div>
            <div class="pa-3">
             

              <v-row>
                <v-col cols="12" md="4">
                  <FieldValue
                    Text="Have you been vaccinated"
                    :value="appData.isVaccinated"
                  ></FieldValue>
                </v-col>
               
              </v-row>
             
            </div>
          </v-card> -->


        </v-col>

        <v-col cols="12" md="4">
          <v-card flat class="mt-3">
            <div class="cardHeader">Headshot Picture</div>
            <div class="pa-3 text-lg-center" v-if="appData.picHeadShot != null">
              <viewer :images="[this.$apiPath + appData.picHeadShot]">
                <img
                  :src="this.$apiPath + appData.picHeadShot"
                  :key="src"
                  class="imgId"
                />
              </viewer>
              <v-btn
                text
                color="primary"
                class="pa-3 text-lg-center"
                v-if="
                  appData.status_named != 'Approved' &&
                  appData.status_named != 'Printed'
                "
                @click="btncrop"
              >
                <v-icon left>mdi-crop</v-icon>
                CROP
              </v-btn>
            </div>
            <!-- <v-card-actions>
               <v-spacer></v-spacer>
               <v-btn @click="btncrop">Crop</v-btn>
             </v-card-actions> -->
          </v-card>

          <v-card flat class="mt-3">
            <div class="cardHeader">ID Proof - Front</div>
            <div class="pa-3 text-lg-center" v-if="appData.picIdFront != null">
              <viewer :images="[this.$apiPath + appData.picIdFront]">
                <img
                  :src="this.$apiPath + appData.picIdFront"
                  :key="src"
                  class="imgId"
                  width="100%"
                />
              </viewer>

               <!-- <viewer :images="[this.$apiPath + appData.picIdFront]">
              <img
                :src="this.$apiPath + appData.picIdFront"
                :key="src"
                class="imgId"
                width="100%"
              />
            </viewer> -->
            </div>
          </v-card>

           <v-card flat class="mt-3">
            <div class="cardHeader">ID Proof - Back</div>
            <div class="pa-3 text-lg-center" v-if="appData.picIdBack != null">
              <viewer :images="[this.$apiPath + appData.picIdBack]">
                <img
                  :src="this.$apiPath + appData.picIdBack"
                  :key="src"
                  class="imgId"
                  v-if="appData.picIdBack"
                  width="100%"
                />
              </viewer>
            </div>

            <div
              class="pa-3 text-lg-center"
              style="font-size: 15px; color: red"
              v-else
            >
              Not Uploaded
            </div>
          </v-card> 

          <!-- <v-card flat class="mt-3">
            <div class="cardHeader">Covid 19 Vaccination Certificate</div>
            <div class="pa-3">
              <v-card flat class="mt-6">
                <div class="text-center pa-2">
                  <v-btn
                    v-if="appData.covid_certificate != null"
                    outlined
                    color="primary"
                    @click="btnviewcertificate"
                    >View Certificate
                    <v-icon right>mdi-book-search-outline</v-icon></v-btn
                  >
                  <div style="font-size: 15px; color: red" v-else>
                    Not Uploaded
                  </div>
                </div>
              </v-card>
            </div>
          </v-card> -->
        </v-col>
      </v-row>
    </v-container>
    <!-- v-if="appstatus == '2'" -->
    <div v-if="this.$store.state.eventInfo.UType != 'MediaAdmin'">
    <div
      v-if="appstatus != '7'"
      style="
        z-index: 1002;
        position: sticky;
        bottom: 0px;
        max-width: 700px;
        margin: auto;
      "
    >
      <v-row class="pa-0 ma-0">
        <v-col cols="12" md="4">
          <v-select
            label="Select Status"
            v-model="appData.status_id"
            :items="StatusForUpdate"
            item-text="status_name"
            item-value="status_id"
            dense
            outlined
            @change="getreson"
            hide-details
          ></v-select>
        </v-col>

        <v-col cols="12" md="5" v-if="appData.status_id != 3">
          <v-select
            label="Select Reason"
            v-model="appData.reason_id"
            :items="appreasonlist"
            item-text="reason_name"
            item-value="reason_id"
            dense
            outlined
            hide-details
          ></v-select>
        </v-col>
        <v-col cols="12" md="5" v-if="appData.status_id == 3">
          <v-select
            label="Select Card Type"
            v-model="appData.card_id"
            :items="CardTypeForUpdate"
            item-text="card_name"
            item-value="card_id"
            dense
            outlined
            hide-details
          ></v-select>
        </v-col>

        <!-- <v-col cols="12" md="2">
          <v-text-field
            v-if="appData.status_id == 3"
            v-model="appData.mName"
            maxlength="4"
            @keypress="filterNum(event)"
            outlined
            dense
            hide-details
            label="Unique Id"
          ></v-text-field>
        </v-col> -->

        <v-col cols="12" md="3">
          <v-btn medium color="primary" @click="btnupdate" class="fullwidth">
            CONFIRM
          </v-btn>
        </v-col>
      </v-row>
    </div>
     </div>

    <v-snackbar v-model="snackbar" timeout="1000" top :color="snackbarcolor">
      {{ snackbartext }}
    </v-snackbar>

    <v-dialog v-model="modelPhoto" max-width="500">
      <v-card flat class="pa-5">
        <div class="content">
          <section class="cropper-area">
            <div class="img-cropper">
              <VueCropper
                ref="cropper"
                :aspect-ratio="22 / 24"
                :src="editpath"
                :key="editpath"
                style="max-height: 400px"
                id="yourImageTag"
              />

              <!-- <VueCropper
                    ref="cropper"
                    :aspect-ratio="11 / 12"
                    :src="editpath"
                    style="max-height: 400px"
                  /> -->
            </div>
          </section>
          <div class="pt-3 text-center">
            <div
              style="
                border: 1px solid #e6e6e6;
                background-color: #fafafa;
                border-radius: 8px;
                padding: 3px 0px;
              "
            >
              <v-btn icon small @click="imageOpt(0)" class="mr-3">
                <v-icon>mdi-magnify-plus</v-icon>
              </v-btn>
              <v-btn icon small @click="imageOpt(1)" class="mr-3">
                <v-icon>mdi-magnify-minus</v-icon>
              </v-btn>
              <v-btn icon small @click="imageOpt(2)" class="mr-3">
                <v-icon>mdi-flip-horizontal</v-icon>
              </v-btn>
              <v-btn icon small @click="imageOpt(3)" class="mr-3">
                <v-icon>mdi-rotate-right</v-icon>
              </v-btn>
              <v-btn icon small @click="imageOpt(4)" class="mr-3">
                <v-icon>mdi-arrow-left-bold</v-icon>
              </v-btn>
              <v-btn icon small @click="imageOpt(5)" class="mr-3">
                <v-icon>mdi-arrow-right-bold</v-icon>
              </v-btn>
              <v-btn icon small @click="imageOpt(6)" class="mr-3">
                <v-icon>mdi-arrow-up-bold</v-icon>
              </v-btn>
              <v-btn icon small @click="imageOpt(7)" class="mr-3">
                <v-icon>mdi-arrow-down-bold</v-icon>
              </v-btn>
              <v-btn icon small @click="imageOpt(8)">
                <v-icon>mdi-lock-reset</v-icon>
              </v-btn>
            </div>
          </div>
        </div>
        <v-card-actions class="pt-4 text-center">
          <v-btn color="error" outlined @click="close">Cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" @click="closeDialog">Submit</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      max-width="600"
      scrollable
      v-model="dialogpdf"
      :fullscreen="fullscreen1"
    >
      <v-card flat>
        <v-toolbar flat>
          <div style="font-weight: 600">COVID 19 - Certificate</div>
          <v-spacer></v-spacer>
          <v-btn right icon color="red" @click="makefulldilog"
            ><v-icon>{{ maxiconname }}</v-icon></v-btn
          >
          <v-btn right icon color="red" @click="dialogpdf = false"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-divider></v-divider>
        <v-card-text
          style="height: 600px"
          v-if="this.appData.covid_certificate != null"
        >
          <pdf
            v-if="
              this.appData.covid_certificate.substring(
                this.appData.covid_certificate.length - 3
              ) == 'pdf'
            "
            :src="this.$apiPath + appData.covid_certificate"
            :key="this.$apiPath + appData.covid_certificate"
          >
          </pdf>
          <v-img
            v-else
            :src="this.$apiPath + appData.covid_certificate"
            :key="this.$apiPath + appData.covid_certificate"
          >
          </v-img>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import "viewerjs/dist/viewer.css";
//import Viewer from "v-viewer";
import FieldValue from "./FieldValue";
//import Vue from "vue";
//import axios from "axios";
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
import pdf from "vue-pdf";

//import moment from 'moment'
//Vue.use(Viewer);
export default {
  // props: { appData: {},appstatus: {}, },
  props: ["appData", "appstatus", "appreasonlist"],
  components: { VueCropper, FieldValue, pdf },
  data: () => ({
    maxiconname: "mdi-fullscreen",
    dialogpdf: false,
    fullscreen1: false,
    modelPhoto: false,
    picpathbackup: "",
    picpath: "",
    editpath: "",
    snackbar: false,
    snackbartext: "",
    snackbarcolor: "primary",
    images: [],
    Stand: [],
    CompanyType: [],
    JobType: [],
    SelStand: "",
    mediaphoto: {
      event_id: "",
      venue_id: "",
      vendor_id: "",
      application_id: "",
      firstName: "",
      photo: "",
    },
    StatusForUpdate: [
      {
        status_id: 3,
        status_name: "Approve",
        status: "O",
        status_named: "Approved",
      },
      {
        status_id: 4,
        status_name: "Amend",
        status: "O",
        status_named: "Amended",
      },

      {
        status_id: 6,
        status_name: "Reject",
        status: "O",
        status_named: "Rejected",
      },
    ],
    StatusMessage: "",
    ResonForUpdate: [],
    CardTypeForUpdate: [],
    src: "",
    Status: "",
    Reson: "",
    PostData: {
      event_id: "",
      eaid: 0,
      companytype_id: "",
      jobtype_id: "",
      venue_id: "",
      preferedCity:0,
      vendor_id: "",
      status_id: "",
      reason_id: 0,
      card_id: "",
      mname: "",
      access:"",
      sangeet:0,
      wedding12:0,
      wedding13:0,
      wedding14:0,
      wedding15:0,
      validTo:"",
      CurrentType:0,


      azone: [],
    },
  }),

  mounted() {
    // alert(appstatus);
    // sessionStorage.EVENTID = "1";
    // sessionStorage.VENUEID = "1";
    // sessionStorage.VENDORID = "1";

    this.bindjobtype(
      this.$store.state.eventInfo.EventId,
      this.$store.state.eventInfo.VenueId
    );
    //this.bindStatus();
    this.bindCartype(this.$store.state.eventInfo.EventId);
    this.bindReson(this.appData.status_id);
    this.bindCampanytype(this.$store.state.eventInfo.EventId);
    //  alert(this.appstatus);
    // this.SelStand = this.appData.zone;
    this.bindjobforedit(this.appData.companytype_id);
  },

  watch: {
    "appData.companytype_id": function () {
      this.bindjobforedit(this.appData.companytype_id);
    },
  },

  methods: {
    filterNum: function (evt) {
      evt = evt ? evt : window.event;
      let expect = evt.target.value.toString() + evt.key.toString();

      if (!/^\d+$/.test(expect)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

      savedob(date) {
      
      this.$refs.menu2.save(date);
    },

    makefulldilog: function () {
      this.fullscreen1 = !this.fullscreen1;
      if (this.fullscreen1 == true) {
        this.maxiconname = "mdi-fullscreen-exit";
      } else {
        this.maxiconname = "mdi-fullscreen";
      }
    },

    btnviewcertificate: function () {
      this.dialogpdf = true;
    },

    bindjobtype: async function (EventID, VenueID) {
      this.loadingstatus = true;
      await this.$axios
        .get("Stand/GetStandByEidVid/" + EventID + "/" + VenueID)
        .then((res) => {
          this.Stand = res.data.result;
        })
        .catch()
        .finally();
    },

    btnbindjobtype: function () {
      // this.appData.jobtype_id = "";
      this.bindjobforedit(this.appData.companytype_id);
    },

    btnbindjob: function () {
     // this.appData.jobtype_id = "";
      // alert(this.appData.jobtype_id);
      //this.bindjobforedit1(this.appData.companytype_id);
    },

    bindCampanytype: async function (event_id) {
      await this.$axios
        .get("CompanyType/GetByEventId/" + event_id)
        .then((res) => {
          this.CompanyType = res.data.result;
        });
    },

    bindjobforedit: async function (cat_id) {
      await this.$axios
        .get("JobType/GetByEventIDCompanyType/" + this.$store.state.eventInfo.EventId + "/" + cat_id)
        .then((res) => {
          this.JobType = res.data.result;
          // this.JobType.splice(0,0,{jobtype_id: "0", jobtype_name: "Select Job 1" });
          // this.appData.jobtype_id="0";
        });
    },

    btncrop: function () {
      this.$refs.cropper = "";

      this.editpath = this.$apiPath + this.appData.picHeadShot;
      //"/DNA-Logo-mono.png";
      //alert(this.editpath);
      this.modelPhoto = true;
    },

    close: function () {
      this.modelPhoto = false;
    },

    closeDialog: async function () {
      // alert(sessionStorage.COMPANYID);
      this.picpathbackup = this.$refs.cropper
        .getCroppedCanvas({
          width: 220,
          height: 240,
          imageSmoothingQuality: "high",
        })
        .toDataURL("image/jpeg", 1);
      this.picpath = this.$refs.cropper
        .getCroppedCanvas({
          width: 220,
          height: 240,
          imageSmoothingQuality: "high",
        })
        .toDataURL("image/jpeg", 1);

      this.mediaphoto.event_id = this.$store.state.eventInfo.EventId;
      this.mediaphoto.venue_id = this.$store.state.eventInfo.VenueId;
      this.mediaphoto.vendor_id = this.appData.vendor_id;
      this.mediaphoto.application_id = this.appData.application_id;
      this.mediaphoto.firstName = this.appData.fName;

      this.mediaphoto.photo = this.picpath.split(",")[1];
      await this.$axios
        .put("Application/UploadHead", this.mediaphoto)
        .then((res) => {
          if (res.data.status == "Success") {
            this.showSnackbar("Success", "Photo Croped!");
            this.appData.picHeadShot = res.data.result;
          }
        })
        .catch()
        .finally();
      this.close();

      this.modelPhoto = false;
    },

    imageOpt: function (bIndex) {
      switch (bIndex) {
        case 0:
          this.$refs.cropper.relativeZoom(0.2);
          break;
        case 1:
          this.$refs.cropper.relativeZoom(-0.2);
          break;
        case 2:
          // const dom = this.$refs.cropper;
          // let scale = dom.getAttribute("data-scale");
          // scale = scale ? -scale : -1;
          // this.$refs.cropper.scaleX(scale);
          // dom.setAttribute("data-scale", scale);
          break;
        case 3:
          this.$refs.cropper.rotate(90);
          break;
        case 4:
          this.$refs.cropper.move(-10, 0);
          break;
        case 5:
          this.$refs.cropper.move(10, 0);
          break;
        case 6:
          this.$refs.cropper.move(0, -10);
          break;
        case 7:
          this.$refs.cropper.move(0, 10);
          break;
        case 8:
          this.$refs.cropper.reset();
      }
    },

    bindStatus: async function () {
      await this.$axios.get("Status").then((res) => {
        this.StatusForUpdate = res.data.result;
      });
    },

    bindCartype: async function (event_id) {
      // await this.$axios.get("Cards/GetByEventId/" + event_id).then((res) => {
      //   this.CardTypeForUpdate = res.data.result;
      // });
console.log(event_id);
//alert(sessionStorage.ETID);
if(sessionStorage.ETID==1)
{
this.CardTypeForUpdate=[
{card_name:'SETUP - ARTIST',
  card_id:1
},
{card_name:'SETUP - EVENT',
  card_id:2
},
{card_name:'SETUP - RIL STAFF',
  card_id:3
},
{card_name:'SETUP - PLAZA',
  card_id:4
},

      ]
}
else{
this.CardTypeForUpdate=[
{card_name:'RIL - FOH',
  card_id:5
},
{card_name:'RIL - BOH',
  card_id:6
},
{card_name:'RIL - ALL AREA',
  card_id:7
},
{card_name:'ARTIST',
  card_id:8
},

{card_name:'VENDOR - FOH',
  card_id:9
},
{card_name:'VENDOR - BOH',
  card_id:10
},

      ]
}

      
    },


    btnsaveonly: async function () {
this.overlay=true;
      this.PostData.event_id = this.$store.state.eventInfo.EventId;
      this.PostData.venue_id = this.$store.state.eventInfo.VenueId;
      this.PostData.vendor_id = this.appData.vendor_id;
      this.PostData.status_id = this.appData.status_id;
      this.PostData.companytype_id = this.appData.companytype_id;
      this.PostData.jobtype_id = this.appData.jobtype_id;
      this.PostData.eaid = this.$store.state.eventInfo.EventAdminID;
      this.PostData.preferedCity =  this.$store.state.eventInfo.VenueId;
      this.PostData.access = this.appData.access;
      this.PostData.validTo = this.appData.validTo;
      this.PostData.CurrentType=this.appData.currentType;
      if(this.appData.sangeet==true)
      {
        this.PostData.sangeet = 1;
      }
      else
      {
this.PostData.sangeet = 0;
      }

         if(this.appData.wedding12==true)
      {
this.PostData.wedding12 = 1;
      }
      else
      {
this.PostData.wedding12 = 0;
      }

         if(this.appData.wedding13==true)
      {
this.PostData.wedding13 = 1;
      }
      else
      {
this.PostData.wedding13 = 0;
      }

           if(this.appData.wedding14==true)
      {
this.PostData.wedding14 = 1;
      }
      else
      {
this.PostData.wedding14 = 0;
      }

           if(this.appData.wedding15==true)
      {
this.PostData.wedding15 = 1;
      }
      else
      {
this.PostData.wedding15 = 0;
      }
      
      //this.PostData.wedding12 = this.appData.wedding12;
     // this.PostData.wedding13 = this.appData.wedding13;
      //this.PostData.wedding14 = this.appData.wedding14;


      if (this.appData.status_id == "3") {
        this.PostData.reason_id = "0";
        this.PostData.card_id = this.appData.card_id;
        this.PostData.mname = this.appData.mName;
      } else {
        this.PostData.card_id = "0";
        this.PostData.reason_id = this.appData.reason_id;
        this.PostData.mname = "";
      }

      // if (this.appData.jobtype_id == "") {
      //   this.showSnackbar("error", "Select Jobtype !");
      //   return;
      // }

      this.PostData.azone = this.appData.zone;
      console.log("For check",this.PostData);
      this.StatusMessage = "Application Saved Only";
      await this.$axios
        .put(
          "Application/ChangeAppStatus/" + this.appData.application_id,
          this.PostData
        )
        .then((res) => {
          if (res.data.status == "Success") {
            // this.Reload(sessionStorage.EVENTID, sessionStorage.VENUEID);
            this.showSnackbar("success", this.StatusMessage);
            this.overlay=false;
          } else {
            if (res.data.message == "ALREADY EXISTS") {
              this.showSnackbar("error", "Refrence number already exists !");
              this.overlay=false;
            } else {
              this.showSnackbar("error", res.data.message);
              this.overlay=false;
            }
          }
        })
        .catch()
        .finally();
    },

    btnupdate: async function () {
     // alert(this.$store.state.eventInfo.UType);
      // alert(this.appData.status_id + "/reasonid-" + this.appData.reason_id + "/cardid-" + this.appData.card_id);
      if (this.appData.status_id == "2") {
        this.showSnackbar("error", "Select Status !");
        return;
      }

      if (this.appData.status_id == "3") {
        if (this.appData.card_id == "0") {
          this.showSnackbar("error", "Select Card !");
          return;
        }

        // if (this.appData.mName == "") {
        //   this.showSnackbar("error", "Enter Unique Id !");
        //   return;
        // }
      }

      if (this.appData.status_id != "3") {
        if (this.appData.reason_id.length <= 0) {
          this.showSnackbar("error", "Select Reason !");
          return;
        }
      }

      if (this.appData.status_id == "3") {
        this.StatusMessage = "Application Approved";
      } else if (this.appData.status_id == "4") {
        this.StatusMessage = "Application Amended";
      } else {
        this.StatusMessage = "Application Rejected";
      }

      this.PostData.event_id = this.$store.state.eventInfo.EventId;
      this.PostData.venue_id = this.$store.state.eventInfo.VenueId;
      this.PostData.vendor_id = this.appData.vendor_id;
      this.PostData.status_id = this.appData.status_id;
      this.PostData.companytype_id = this.appData.companytype_id;
      this.PostData.jobtype_id = this.appData.jobtype_id;
      this.PostData.eaid = this.$store.state.eventInfo.EventAdminID;
      this.PostData.CurrentType=this.appData.currentType;
this.PostData.validTo = this.appData.validTo;
      // this.PostData.reason_id= this.appData.reason_id;
      // this.PostData.card_id= this.appData.card_id

this.PostData.preferedCity =  this.$store.state.eventInfo.VenueId;
      this.PostData.access = this.appData.access;
      if(this.appData.sangeet==true)
      {
this.PostData.sangeet = 1;
      }
      else
      {
this.PostData.sangeet = 0;
      }

         if(this.appData.wedding12==true)
      {
this.PostData.wedding12 = 1;
      }
      else
      {
this.PostData.wedding12 = 0;
      }

         if(this.appData.wedding13==true)
      {
this.PostData.wedding13 = 1;
      }
      else
      {
this.PostData.wedding13 = 0;
      }

           if(this.appData.wedding14==true)
      {
this.PostData.wedding14 = 1;
      }
      else
      {
this.PostData.wedding14 = 0;
      }

       if(this.appData.wedding15==true)
      {
this.PostData.wedding15 = 1;
      }
      else
      {
this.PostData.wedding15 = 0;
      }


      if (this.appData.status_id == "3") {
        this.PostData.reason_id = "0";
        this.PostData.card_id = this.appData.card_id;
        this.PostData.mname = this.appData.mName;
      } else {
        this.PostData.card_id = "0";
        this.PostData.reason_id = this.appData.reason_id;
        this.PostData.mname = "";
      }

      if (this.appData.jobtype_id == "") {
        this.showSnackbar("error", "Select Jobtype !");
        return;
      }

      // if(this.appData.reason_id==""){
      // this.PostData.reason_id ="0";
      //       }
      //       else
      //       {
      // this.PostData.reason_id = this.appData.reason_id;
      //       }

      //          if(this.appData.card_id==""){
      // this.PostData.card_id ="0";
      //       }
      //       else
      //       {
      // this.PostData.card_id = this.appData.card_id;
      //       }

      this.PostData.azone = this.appData.zone;

      if(this.$store.state.eventInfo.UType=="SubAdmin" && this.appData.status_id == "3")
      {
this.PostData.status_id=2;
      }
     

      console.log("For check",this.PostData);

      await this.$axios
        .put(
          "Application/ChangeAppStatus/" + this.appData.application_id,
          this.PostData
        )
        .then((res) => {
           console.log("after data",res.data);
          if (res.data.status == "Success") {
            // this.Reload(sessionStorage.EVENTID, sessionStorage.VENUEID);
            this.showSnackbar("success", this.StatusMessage);
          }else {
            if (res.data.message == "ALREADY EXISTS") {
              this.showSnackbar("error", "Refrence number already exists !");
            }
            else{
             
              this.showSnackbar("error", res.data.message);
            }
          }
        })
        .catch()
        .finally();
    },

    showSnackbar: function (scolor, stext) {
      this.snackbar = true;
      this.snackbartext = stext;
      this.snackbarcolor = scolor;
    },

    bindReson: async function (StatusID) {
      await this.$axios.get("Reason/GetByStatusID/" + this.$store.state.eventInfo.EventId + "/" + StatusID).then((res) => {
        this.appreasonlist = res.data.result;
      });
    },

    getreson: function () {
      // alert(this.SelstatusForUpade);
      this.appData.reason_id = "";
      this.appreasonlist = [];
      this.bindReson(this.appData.status_id);
    },

    appsave: function () {
      // alert(this.appData.zone);
    },
  },
};
</script>

<style scoped>
.imgId {
  border-radius: 10px;
  box-shadow: 0 0 10px #ccc;
  cursor: pointer;
  height: 200px;
}
</style>