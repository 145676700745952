<template>
  <div class="pa-2">
    <v-card class="pa-2 mb-2" flat>
      <v-row>

 <v-col cols="12" md="2">
          <v-select
            label="Select Type"
            v-model="SelEventtype"
            :items="EventType"
            item-text="eventtype_name"
            item-value="eventtype_id"
            dense
            outlined
            hide-details
            @change="btnSetEventtype"
          ></v-select>
        </v-col>

        <v-col cols="12" md="4">
          <v-select
            label="Select Company"
            v-model="SelCompany"
            :items="Company"
            item-text="company_name"
            item-value="company_id"
            dense
            outlined
            hide-details
          ></v-select>
        </v-col>

        <v-col cols="12" md="4">
          <v-select
            label="Select Status"
            v-model="Selstatus"
            :items="Status"
            item-text="status_named"
            item-value="status_id"
            dense
            outlined
            hide-details
          ></v-select>
        </v-col>

        <v-col cols="12" md="2">
        
          <v-btn medium color="primary" @click="btnsearch" class="ml-1">
            <v-icon left>mdi-reload</v-icon>Load Data</v-btn
          >
        </v-col>
        <v-col cols="12" md="2" v-if="desserts.length > 0">
          <export-excel :data="TDselected" type="button">
            <v-btn color="success"
              ><v-icon left>mdi-microsoft-excel</v-icon>DOWNLOAD</v-btn
            >
          </export-excel>
        </v-col>
      </v-row>
    </v-card>
    <v-card class="pa-1 mb-2" flat>
      <v-data-table
        v-model="TDselected"
        :headers="headers"
        :items="desserts"
        item-key="application_id"
        :search="search"
        :show-select="showselectIsenable"
        :loading="loadingstatus"
        loading-text="Loading... Please wait"
        style="border-radius: 0px"
      >
        <template v-slot:top>
          <v-card  style="position: sticky; top: 0px; z-index: 5" flat>
            <v-card-actions>
              <div v-if="TDselected.length > 0 && Selstatus != 7">
                <v-row>
                  <v-col cols="12" md="5" class="py-0">
                    <v-select
                      label="Select Status"
                      v-model="SelstatusForUpade"
                      :items="StatusForUpdate"
                      item-text="status_name"
                      item-value="status_id"
                      dense
                      outlined
                      hide-details
                      @change="getreson"
                    ></v-select>
                  </v-col>

                  <v-col
                    cols="12"
                    md="5"
                    class="py-0"
                    v-if="strcheckatatus != 3 && strcheckatatus != 2"
                  >
                    <v-select
                      label="Select Reason"
                      v-model="SelReasonForUpdate"
                      :items="ResonForUpdate"
                      item-text="reason_name"
                      item-value="reason_id"
                      outlined
                      hide-details
                      dense
                    ></v-select>
                  </v-col>

                  <v-col
                    cols="12"
                    md="5"
                    class="py-0"
                    v-if="strcheckatatus == 3"
                  >
                    <v-select
                      label="Select Card Type"
                      v-model="SelCardTypeForUpdate"
                      :items="CardTypeForUpdate"
                      item-text="card_name"
                      item-value="card_id"
                      hide-details
                      outlined
                      dense
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="2" class="py-0">
                    <v-btn
                      medium
                      color="primary"
                      @click="btnsave"
                      class="fullwidth"
                    >
                      Confirm
                    </v-btn>
                  </v-col>
                </v-row>
              </div>


              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                dense
                solo-inverted
                flat
                label="Search1"
                hide-details
                style="max-width: 400px"
              ></v-text-field>
            </v-card-actions>
          </v-card>
        </template>
        <!-- @click="BtnApprove(index)" -->
        
        <template v-slot:item.application_id="{ item }">
          
          <tr
            @click="BtnApprove(item.s_no, item.status_id)"
            style="cursor: pointer"
          >
            <td style="text-align: left; width: 65px; padding-top: 6px">
              <img
                :src="$apiPath + item.picHeadShot"
                height="40px"
                width="40px"
                style="border-radius: 40px"
              />
            </td>
           
          



            <!-- <td>{{ item.fname }} {{item.lname}}</td>
          <td>{{ item.jobtype_name }}</td>
          <td>{{ item.print_text }}</td>
<td>{{ item.status_named }}</td>-->
          </tr>
        </template>

         <template v-slot:item.gt="{ item }">
            <td>{{GetPrintText("GT",item.print_text)}}</td>
           
           </template>

            <template v-slot:item.das="{ item }">
            <td >{{GetPrintText("DAS",item.print_text)}}</td>
           </template>

           <template v-slot:item.ah="{ item }">
            <td >{{GetPrintText("AH",item.print_text)}}</td>
           </template>
           <template v-slot:item.pav="{ item }">
            <td >{{GetPrintText("PAV",item.print_text)}}</td>
           </template>
           <template v-slot:item.lv3="{ item }">
            <td >{{GetPrintText("LV3",item.print_text)}}</td>
           </template>
           <template v-slot:item.cc="{ item }">
            <td >{{GetPrintText("CC",item.print_text)}}</td>
           </template>

      



    

        <template v-slot:no-data>
          <!-- <v-btn color="primary" @click="initialize">Reset</v-btn> -->
          No Record(s)
        </template>
      </v-data-table>
    </v-card>

    <v-dialog v-model="isViewAppOpened" fullscreen style="z-index: 1000">
      <v-card flat style="background-color: rgb(233 233 233)">
        <v-toolbar dark :color="getColorByStatus(cApp.status_named)" dense>
          <v-toolbar-title
            >Application No: {{ cApp.app_id }} -
            {{ cApp.status_named }}</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon dark @click="btnclosesingleapp">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <ViewApplicationLFW
          v-if="this.$store.state.eventInfo.templateId == 1"
          :appData="cApp"
          :appstatus="appstatus"
          :appreasonlist="ResonForUpdate"
        >
        </ViewApplicationLFW>

        <ViewApplicationPkl
          v-if="this.$store.state.eventInfo.templateId == 2"
          :appData="cApp"
          :appstatus="appstatus"
          :appreasonlist="ResonForUpdate"
          :reload="reloadCurrentApp"
        >
        </ViewApplicationPkl>

        <ViewApplicationDnaEvent
          v-if="this.$store.state.eventInfo.templateId == 3"
          :appData="cApp"
          :appstatus="appstatus"
          :appreasonlist="ResonForUpdate"
        >
        </ViewApplicationDnaEvent>

        <ViewApplicationPkl
          v-if="this.$store.state.eventInfo.templateId == 4"
          :appData="cApp"
          :appstatus="appstatus"
          :appreasonlist="ResonForUpdate"
        >
        </ViewApplicationPkl>

        <ViewApplicationIcc
          v-if="this.$store.state.eventInfo.templateId == 5"
          :appData="cApp"
          :appstatus="appstatus"
          :appreasonlist="ResonForUpdate"
        >
        </ViewApplicationIcc>

        <ViewApplicationPklWid
          v-if="this.$store.state.eventInfo.templateId == 6"
          :appData="cApp"
          :appstatus="appstatus"
          :appreasonlist="ResonForUpdate"
        ></ViewApplicationPklWid>

        <ViewApplicationIcc
          v-if="this.$store.state.eventInfo.templateId == 7"
          :appData="cApp"
          :appstatus="appstatus"
          :appreasonlist="ResonForUpdate"
          :reload="reloadCurrentApp"
        >
        </ViewApplicationIcc>

         <ViewApplicationDnaNmacc
          v-if="this.$store.state.eventInfo.templateId == 8"
          :appData="cApp"
          :appstatus="appstatus"
          :appreasonlist="ResonForUpdate"
        >
        </ViewApplicationDnaNmacc>


          <ViewApplicationSuperCR
          v-if="this.$store.state.eventInfo.templateId == 10"
          :appData="cApp"
          :appstatus="appstatus"
          :appreasonlist="ResonForUpdate"
        >
        </ViewApplicationSuperCR>

        <v-footer
          class="mt-1 pa-3"
          style="
            bottom: 0px;
            left: 0px;
            z-index: 1001;
            position: fixed;
            width: 100%;
          "
        >
          <v-btn
            medium
            color="primary"
            @click="appPrevious"
            :disabled="btnpredisable"
            class="ml-1"
          >
            Previous
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            medium
            color="primary"
            @click="appNext"
            :disabled="btnnextdisable"
            class="ml-1"
          >
            Next
          </v-btn>
        </v-footer>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" timeout="1000" top :color="snackbarcolor">
      {{ snackbartext }}
    </v-snackbar>

    <v-overlay :absolute="absolute" :value="overlay" style="z-index: 9000">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
//import { mapMutations } from 'vuex'
//import axios from "axios";
//import moment from "moment";
import ViewApplicationPkl from "../../components/Admin/ViewApplicationPkl.vue";
import ViewApplicationPklWid from "../../components/Admin/ViewApplicationPklWid.vue";
import ViewApplicationDnaEvent from "../../components/Admin/ViewApplicationDnaEvent.vue";
import ViewApplicationLFW from "../../components/Admin/ViewApplicationLFW.vue";
import ViewApplicationIcc from "../../components/Admin/ViewApplicationIcc.vue";
import ViewApplicationDnaNmacc from "../../components/Admin/ViewApplicationDnaNmacc.vue";
import ViewApplicationSuperCR from "../../components/Admin/ViewApplicationSuperCR.vue";

export default {
  components: {
    ViewApplicationPkl,
    ViewApplicationPklWid,
    ViewApplicationLFW,
    ViewApplicationDnaEvent,
    ViewApplicationIcc,
    ViewApplicationDnaNmacc,
    ViewApplicationSuperCR
  },
  data() {
    return {
 SelEventtype: "",
      EventType: [
        {
           eventtype_id: 1,
              eventtype_name: "Setup",
        },
        {
           eventtype_id: 2,
              eventtype_name: "Event",
        },


      ],

      showApprovalControls:false,
      showselectIsenable:true,
      absolute: true,
      btnnextdisable: false,
      btnpredisable: false,
      TDselected: [],
      singleSelect: false,
      selected: [],
      chkselected: [],
      cApp: {},
      appstatus: "none",
      cAppIndex: 0,
      date: new Date().toISOString().substr(0, 10),
      time: "",
      datebulk: new Date().toISOString().substr(0, 10),
      timebulk: "",
      isViewAppOpened: false,
      modal2: false,
      modal: false,
      overlay: false,
      modaldatebulk: false,
      modaltimebulk: false,
      menu: false,
      menu2: false,
      snackbar: false,
      snackbartext: "",
      snackbarcolor: "primary",
      EntryMode: "",
      valid: false,
      validbulk: false,
      mode: "NEW",
      newmode: true,
      sheet: true,
      sheetbulk: false,
      search: "",
      loadingstatus: false,
      cardID: "",
      Selcompanytype: "",
      event_id: "",

      StatusMessage: "",

      Selstatus: "8",
      Status: [],
      SelstatusForUpade: "",

      StatusForUpdate: [],

      SelReasonForUpdate: "",
      ResonForUpdate: [],

      SelCardTypeForUpdate: "",
      CardTypeForUpdate: [],
      strcheckatatus: "",

      SelCompany: "",
      Company: [],

      //companytype: [],
      companytype_id: [],
      postBody: {
        company_name: "",
        event_id: "",
        venue_id: "",
        vendor_name: "",
        vendor_email: "",
        no_of_application: "",
        deadline: "",
        companytype_id: [],
        Vendor_ID_Update: "",
        uflag: "0",
        FlagCompanyName: "",
        FlagEmail: "",
      },

      postBodyBulk: {
        event_id: "",
        venue_id: "",
        deadline: "",
      },
      postBulkUpdate: {
        application_id: [],
        event_id: "",
        venue_id: "",
        //  vendor_id: "",
        status_id: "",
        reason_id: "",
        card_id: "",
        eaid: 0,
      },
      headers: [
        {
          text: "AppID",
          align: "left",
          value: "app_id",
          class: "blue lighten-5 w-140",
        },

        {
          text: "Photo",
          align: "left",
          value: "application_id",
          class: "blue lighten-5 w-140",
        },

       

        {
          text: "Name",
          value: "fName",
          align: "left",
          class: "blue lighten-5",
        },
        {
          text: "Company",
          value: "company_name",
          align: "left",
          class: "blue lighten-5",
        },
        {
          text: "Designation",
          value: "jobtype_name",
          align: "left",
          class: "blue lighten-5",
        },

        {
          text: "CompanyType",
          value: "companytype_name",
          align: "left",
          class: "blue lighten-5",
        },

          
        {
          text: "GT",
          value: "gt",
          align: "left",
          sortable: true,
          class: "blue lighten-5 w-140",
          
        },

         {
          text: "DAS",
           value: "das",
          align: "left",
          sortable: true,
          key:'das',
          class: "blue lighten-5 w-140",
        },

        {
          text: "AH",
           value: "ah",
          align: "left",
          class: "blue lighten-5",
        },

         {
          text: "PAV",
           value: "pav",
          align: "left",
          class: "blue lighten-5",
        },

         {
          text: "LV3",
           value: "lv3",
          align: "left",
          class: "blue lighten-5",
        },
          {
          text: "CC",
           value: "cc",
          align: "left",
          class: "blue lighten-5",
        },

        
        // {
        //   text: "Area",
        //   value: "print_text",
        //   align: "left",
        //   class: "blue lighten-5",
        // },

        {
          text: "Zone",
          value: "access",
          align: "left",
          class: "blue lighten-5",
        },

        {
          text: "Setup",
          value: "setup",
          align: "left",
          class: "blue lighten-5",
        },

         {
          text: "5Th",
          value: "event_05",
          align: "left",
          class: "blue lighten-5",
        },

        {
          text: "12Th",
          value: "event_12",
          align: "left",
          class: "blue lighten-5",
        },
         {
          text: "13Th",
          value: "event_13",
          align: "left",
          class: "blue lighten-5",
        },
         {
          text: "14Th",
          value: "event_14",
          align: "left",
          class: "blue lighten-5",
        },
         {
          text: "15Th",
          value: "event_15",
          align: "left",
          class: "blue lighten-5",
        },


        {
          text: "App Status",
          value: "status_named",
          align: "left",
          class: "blue lighten-5",
        },
        {
          text: "Card",
          value: "card_name",
          align: "left",
          class: "blue lighten-5",
        },

        {
          text: "Reason",
          value: "reason_name",
          align: "left",
          class: "blue lighten-5",
        },

       
      ],
      desserts: [],
      Vcompany_name: [
        (v) => !!v || "Company Name is required",
        // v => /.+@.+\..+/.test(v) || "E-mail must be valid"
      ],

      Vdatebulk: [
        (v) => !!v || "Date required",
        // v => /.+@.+\..+/.test(v) || "E-mail must be valid"
      ],

      Vtimebulk: [
        (v) => !!v || "Time required",
        // v => /.+@.+\..+/.test(v) || "E-mail must be valid"
      ],
    };
  },
  mounted() {
    this.checkAppStatus();
    this.setStatusOnload();
    this.setStatusForupdateOnload();
    this.setshowselectIsenable();
    //alert(sessionStorage.ETID);
    // sessionStorage.display_event_name = "IPL T20 2021";
    // sessionStorage.display_venue_name = "Chinaswamy Stadium";

    this.$store.commit("setPageTitle", "Application Manager");
    this.$store.commit("setPageTitleIcon", "mdi-chart-box-plus-outline");
    //this.$store.commit("setEventName", sessionStorage.display_event_name);
    //this.$store.commit("setVenueName", sessionStorage.display_venue_name);
    //alert(this.$store.state.eventInfo.templateId);
    this.bindCartype(this.$store.state.eventInfo.EventId);
    this.bindCompany(
      this.$store.state.eventInfo.EventId,
      this.$store.state.eventInfo.VenueId
    );
    if (sessionStorage.COMPANYID) {
      this.SelCompany = parseInt(sessionStorage.COMPANYID);
      this.Selstatus = parseInt(sessionStorage.STATUSID);
      this.SelEventtype = parseInt(sessionStorage.ETID);
      this.btnsearch();
    }
  },
  watch: {
    TDselected: function () {
      if (this.TDselected.length <= 0) {
        this.SelstatusForUpade = "";
        this.SelReasonForUpdate = "";
        this.SelCardTypeForUpdate = "";
      }
    },
    SelstatusForUpade: function () {
      this.SelReasonForUpdate = "";
      this.SelCardTypeForUpdate = "";
    },
  },
  methods: {
btnSetEventtype:function(){
sessionStorage.ETID=this.SelEventtype;
},

    showSnackbar: function (scolor, stext) {
      this.snackbar = true;
      this.snackbartext = stext;
      this.snackbarcolor = scolor;
    },
    setshowselectIsenable(){
      if(this.$store.state.eventInfo.UType=="SubAdmin")
      {
this.showselectIsenable=false;
      }

       else if(this.$store.state.eventInfo.UType=="MediaAdmin")
      {
this.showselectIsenable=false;
      }


      else{
        this.showselectIsenable=true;
      }

    },

    setStatusForupdateOnload() {
      this.StatusForUpdate = [
        {
          status_id: this.$store.state.eventInfo.UType == "SubAdmin" ? 2 : 3,
          status_name: "Approve",
          status: "O",
          status_named: "Approved",
        },
        {
          status_id: 4,
          status_name: "Amend",
          status: "O",
          status_named: "Amended",
        },

        {
          status_id: 6,
          status_name: "Reject",
          status: "O",
          status_named: "Rejected",
        },
      ];

      // {
      //   status_id: 8,
      //   status_name: "Print",
      //   status: "O",
      //   status_named: "Printed",
      // },
    },

    setStatusOnload() {
     // alert(this.$store.state.eventInfo.approvallevel);
      // if (sessionStorage.ETID==1 && sessionStorage.STATUSID==10) {
if (this.$store.state.eventInfo.UType=="SubAdmin") {
        
       
          this.Status = [
            {
              status_id: 0,
              status_name: "All",
              status: "O",
              status_named: "All",
            },
            {
              status_id: 10,
              status_name: "Pending",
              status: "O",
              status_named: "Pending",
            },
            {
              status_id: 2,
              status_name: "Check",
              status: "O",
              status_named: "Checked",
            },
            {
              status_id: 3,
              status_name: "Approve",
              status: "O",
              status_named: "Approved",
            },
            {
              status_id: 4,
              status_name: "Amend",
              status: "O",
              status_named: "Amended",
            },
            {
              status_id: 6,
              status_name: "Reject",
              status: "O",
              status_named: "Rejected",
            },
            {
              status_id: 7,
              status_name: "Print",
              status: "O",
              status_named: "Printed",
            },
          ];
        
        
      }
      
//      else if (sessionStorage.ETID==1 && sessionStorage.STATUSID==8) {
       
//           this.Status = [
//             {
//               status_id: 0,
//               status_name: "All",
//               status: "O",
//               status_named: "All",
//             },
//             {
//               status_id: 10,
//               status_name: "Pending",
//               status: "O",
//               status_named: "Pending",
//             },
//             {
//               status_id: 8,
//               status_name: "Check",
//               status: "O",
//               status_named: "Checked",
//             },
//             {
//               status_id: 3,
//               status_name: "Approve",
//               status: "O",
//               status_named: "Approved",
//             },
//             {
//               status_id: 4,
//               status_name: "Amend",
//               status: "O",
//               status_named: "Amended",
//             },
//             {
//               status_id: 6,
//               status_name: "Reject",
//               status: "O",
//               status_named: "Rejected",
//             },
//             {
//               status_id: 7,
//               status_name: "Print",
//               status: "O",
//               status_named: "Printed",
//             },
//           ];
        
        
//       }

// else if (this.$store.state.eventInfo.UType=="SubAdmin" && sessionStorage.ETID==1 && sessionStorage.STATUSID==2) {
       
//           this.Status = [
//             {
//               status_id: 0,
//               status_name: "All",
//               status: "O",
//               status_named: "All",
//             },
//             {
//               status_id: 10,
//               status_name: "Pending",
//               status: "O",
//               status_named: "Pending",
//             },
//             {
//               status_id: 2,
//               status_name: "Check",
//               status: "O",
//               status_named: "Checked",
//             },
//             {
//               status_id: 3,
//               status_name: "Approve",
//               status: "O",
//               status_named: "Approved",
//             },
//             {
//               status_id: 4,
//               status_name: "Amend",
//               status: "O",
//               status_named: "Amended",
//             },
//             {
//               status_id: 6,
//               status_name: "Reject",
//               status: "O",
//               status_named: "Rejected",
//             },
//             {
//               status_id: 7,
//               status_name: "Print",
//               status: "O",
//               status_named: "Printed",
//             },
//           ];
        
        
//       }


// else if (this.$store.state.eventInfo.UType=="SubAdmin" && sessionStorage.ETID==1 && sessionStorage.STATUSID==8) {
       
//           this.Status = [
//             {
//               status_id: 0,
//               status_name: "All",
//               status: "O",
//               status_named: "All",
//             },
//             {
//               status_id: 10,
//               status_name: "Pending",
//               status: "O",
//               status_named: "Pending",
//             },
//             {
//               status_id: 8,
//               status_name: "Check",
//               status: "O",
//               status_named: "Checked",
//             },
//             {
//               status_id: 3,
//               status_name: "Approve",
//               status: "O",
//               status_named: "Approved",
//             },
//             {
//               status_id: 4,
//               status_name: "Amend",
//               status: "O",
//               status_named: "Amended",
//             },
//             {
//               status_id: 6,
//               status_name: "Reject",
//               status: "O",
//               status_named: "Rejected",
//             },
//             {
//               status_id: 2,
//               status_name: "Print",
//               status: "O",
//               status_named: "Printed",
//             },
//           ];
        
        
//       }

// else if (this.$store.state.eventInfo.UType=="SubAdmin" && sessionStorage.ETID==2 && sessionStorage.STATUSID==8) {
       
//           this.Status = [
//             {
//               status_id: 0,
//               status_name: "All",
//               status: "O",
//               status_named: "All",
//             },
//             {
//               status_id: 10,
//               status_name: "Pending",
//               status: "O",
//               status_named: "Pending",
//             },
//             {
//               status_id: 8,
//               status_name: "Check",
//               status: "O",
//               status_named: "Checked",
//             },
//             {
//               status_id: 3,
//               status_name: "Approve",
//               status: "O",
//               status_named: "Approved",
//             },
//             {
//               status_id: 4,
//               status_name: "Amend",
//               status: "O",
//               status_named: "Amended",
//             },
//             {
//               status_id: 6,
//               status_name: "Reject",
//               status: "O",
//               status_named: "Rejected",
//             },
//             {
//               status_id: 2,
//               status_name: "Print",
//               status: "O",
//               status_named: "Printed",
//             },
//           ];
        
        
//       }

// else if (this.$store.state.eventInfo.UType=="SubAdmin" && sessionStorage.ETID==1 && sessionStorage.STATUSID==7) {
       
//           this.Status = [
//             {
//               status_id: 0,
//               status_name: "All",
//               status: "O",
//               status_named: "All",
//             },
//             {
//               status_id: 10,
//               status_name: "Pending",
//               status: "O",
//               status_named: "Pending",
//             },
//             {
//               status_id: 8,
//               status_name: "Check",
//               status: "O",
//               status_named: "Checked",
//             },
//             {
//               status_id: 3,
//               status_name: "Approve",
//               status: "O",
//               status_named: "Approved",
//             },
//             {
//               status_id: 4,
//               status_name: "Amend",
//               status: "O",
//               status_named: "Amended",
//             },
//             {
//               status_id: 6,
//               status_name: "Reject",
//               status: "O",
//               status_named: "Rejected",
//             },
//             {
//               status_id: 7,
//               status_name: "Print",
//               status: "O",
//               status_named: "Printed",
//             },
//           ];
        
        
//       }

//       else if (this.$store.state.eventInfo.UType=="Admin" && sessionStorage.ETID==2 && sessionStorage.STATUSID==7) {
       
//           this.Status = [
//             {
//               status_id: 0,
//               status_name: "All",
//               status: "O",
//               status_named: "All",
//             },
//             {
//               status_id: 10,
//               status_name: "Pending",
//               status: "O",
//               status_named: "Pending",
//             },
//             {
//               status_id: 8,
//               status_name: "Check",
//               status: "O",
//               status_named: "Checked",
//             },
//             {
//               status_id: 3,
//               status_name: "Approve",
//               status: "O",
//               status_named: "Approved",
//             },
//             {
//               status_id: 4,
//               status_name: "Amend",
//               status: "O",
//               status_named: "Amended",
//             },
//             {
//               status_id: 6,
//               status_name: "Reject",
//               status: "O",
//               status_named: "Rejected",
//             },
//             {
//               status_id: 7,
//               status_name: "Print",
//               status: "O",
//               status_named: "Printed",
//             },
//           ];
        
        
//       }

//       else if (this.$store.state.eventInfo.UType=="Admin" && sessionStorage.ETID==1 && sessionStorage.STATUSID==2) {
       
//           this.Status = [
//             {
//               status_id: 0,
//               status_name: "All",
//               status: "O",
//               status_named: "All",
//             },
//             {
//               status_id: 2,
//               status_name: "Pending",
//               status: "O",
//               status_named: "Pending",
//             },
            
//             {
//               status_id: 3,
//               status_name: "Approve",
//               status: "O",
//               status_named: "Approved",
//             },
//             {
//               status_id: 4,
//               status_name: "Amend",
//               status: "O",
//               status_named: "Amended",
//             },
//             {
//               status_id: 6,
//               status_name: "Reject",
//               status: "O",
//               status_named: "Rejected",
//             },
//             {
//               status_id: 7,
//               status_name: "Print",
//               status: "O",
//               status_named: "Printed",
//             },
//           ];
        
        
//       }


      else {
        this.Status = [
          {
            status_id: 0,
            status_name: "All",
            status: "O",
            status_named: "All",
          },

          
          {
            status_id: 2,
            status_name: "Pending",
            status: "O",
            status_named: "Pending",
          },
          {
            status_id: 3,
            status_name: "Approve",
            status: "O",
            status_named: "Approved",
          },
          {
            status_id: 4,
            status_name: "Amend",
            status: "O",
            status_named: "Amended",
          },
          {
            status_id: 6,
            status_name: "Reject",
            status: "O",
            status_named: "Rejected",
          },
          {
            status_id: 7,
            status_name: "Print",
            status: "O",
            status_named: "Printed",
          },
        ];
      }
    },
    getColorByStatus(status) {
      if (status == "Submitted") {
        return "blue";
      } else if (status == "Approved") {
        return "green";
      } else if (status == "Amended") {
        return "orange";
      } else if (status == "Cancelled") {
        return "red";
      } else if (status == "Rejected") {
        return "red";
      } else if (status == "Printed") {
        return "teal";
      }
    },
    getreson: function () {
      // alert(this.SelstatusForUpade);
      this.bindReson(this.SelstatusForUpade);
      this.strcheckatatus = this.SelstatusForUpade;
      //console.log("Ratan");
      //console.log(this.strcheckatatus);
    },

    // BtnApprove: function (ID, status_ID) {
    //   this.cAppIndex = ID - 1;
    //   this.cApp = this.desserts[this.cAppIndex];
    //   this.cApp.dob = this.cApp.dob.slice(0, 10);
    //   this.appstatus = status_ID;
    //   sessionStorage.COMPANYID = this.SelCompany;
    //   this.isViewAppOpened = true;
    // },

    btnclosesingleapp: function () {
      this.Reload(
        this.$store.state.eventInfo.EventId,
        this.$store.state.eventInfo.VenueId,
        this.SelCompany,
        this.Selstatus
      );
      this.isViewAppOpened = false;
    },
      GetPrintText(s,str) {
     // const valuesArray = str.split(',');

      if(str.includes(s)==true)
      {
      return "Y";
      }
      else{
        return "N";
      }
      
      },

    BtnApprove: function (ID, status_ID) {
      this.cAppIndex = ID - 1;
      // alert(this.$store.state.eventInfo.EventId + "/" + this.$store.state.eventInfo.VenueId + "/" + this.desserts[this.cAppIndex].application_id + "/" + this.desserts[this.cAppIndex].vendor_id);
      this.binddata(
        this.$store.state.eventInfo.EventId,
        this.$store.state.eventInfo.VenueId,
        this.desserts[this.cAppIndex].vendor_id,
        this.desserts[this.cAppIndex].application_id
      );

      this.cApp = this.desserts[this.cAppIndex];
      // this.cApp.dob = this.cApp.dob.slice(0, 10);
      this.appstatus = status_ID;
      sessionStorage.COMPANYID = this.desserts[this.cAppIndex].vendor_id;
      this.isViewAppOpened = true;
      this.btnnextdiaable();
      // this.btnprediaable();
    },

    btn_reload: function () {
      this.Reload(
        this.$store.state.eventInfo.EventId,
        this.$store.state.eventInfo.VenueId
      );
    },

    btnsearch: function () {
      this.bindCartype(this.$store.state.eventInfo.EventId);
      this.TDselected = [];
      this.desserts = [];
      if (this.SelCompany.length <= 0) {
        this.showSnackbar("error", "Select Company !");
      } else if (this.Selstatus.length <= 0) {
        this.showSnackbar("error", "Select Card !");
      } else {
        this.desserts = [];
        this.chkselected = [];
        this.appstatus = this.Selstatus;
        this.Reload(
          this.$store.state.eventInfo.EventId,
          this.$store.state.eventInfo.VenueId,
          this.SelCompany,
          this.Selstatus
        );
        this.bindReson(this.Selstatus);
      }
    },

    // appNext: function () {
    //   if (this.cAppIndex < this.desserts.length - 1) {
    //     this.cAppIndex = this.cAppIndex + 1;
    //     this.cApp = this.desserts[this.cAppIndex];
    //     this.bindReson(this.cApp.status_id);
    //   }
    // },

reloadCurrentApp: function(){
  this.binddata(
          this.$store.state.eventInfo.EventId,
          this.$store.state.eventInfo.VenueId,
          this.desserts[this.cAppIndex].vendor_id,
          this.desserts[this.cAppIndex].application_id
        );
},

    appNext: function () {
      if (this.cAppIndex < this.desserts.length - 1) {
        this.cAppIndex = this.cAppIndex + 1;
        //  this.cApp = this.desserts[this.cAppIndex];

        this.binddata(
          this.$store.state.eventInfo.EventId,
          this.$store.state.eventInfo.VenueId,
          this.desserts[this.cAppIndex].vendor_id,
          this.desserts[this.cAppIndex].application_id
        );
        this.appstatus = this.desserts[this.cAppIndex].status_id;
        this.bindReson(this.desserts[this.cAppIndex].status_id);
      }
      this.btnnextdiaable();
    },

    // appPrevious: function () {
    //   if (this.cAppIndex > 0) {
    //     this.cAppIndex = this.cAppIndex - 1;
    //     this.cApp = this.desserts[this.cAppIndex];
    //     this.bindReson(this.cApp.status_id);
    //   }
    // },
    checkAppStatus: function () {
      if (this.$store.state.eventInfo.UType == "SubAdmin") {
        this.showApprovalControls = false;
      }
      else{
        this.showApprovalControls = true;
      }
    },

    appPrevious: function () {
      if (this.cAppIndex > 0) {
        this.cAppIndex = this.cAppIndex - 1;

        this.binddata(
          this.$store.state.eventInfo.EventId,
          this.$store.state.eventInfo.VenueId,
          this.desserts[this.cAppIndex].vendor_id,
          this.desserts[this.cAppIndex].application_id
        );
        this.appstatus = this.desserts[this.cAppIndex].status_id;
        this.bindReson(this.desserts[this.cAppIndex].status_id);
      }
      this.btnprediaable();
    },

    btnnextdiaable: function () {
      if (this.desserts.length - 1 == 0) {
        this.btnnextdisable = true;
        this.btnpredisable = true;
        // alert("in");
        return;
      } else if (this.cAppIndex < this.desserts.length - 1) {
        this.btnnextdisable = false;
        this.btnpredisable = false;
      } else {
        this.btnnextdisable = true;
        this.btnpredisable = false;
      }
    },

    btnprediaable: function () {
      if (this.cAppIndex > 0) {
        this.btnpredisable = false;
        this.btnnextdisable = false;
      } else {
        this.btnpredisable = true;
        this.btnnextdisable = false;
      }
    },

    bindCompany: async function (EventID, VenueID) {
      await this.$axios
        .get(
          "vendor/GetByEventId/" +
            EventID +
            "/" +
            VenueID +
            "/" +
            this.$store.state.eventInfo.EventAdminID
        )
        .then((res) => {
          if (res.data.result.length > 0) {
            this.Company = res.data.result;
            this.Company.splice(0, 0, { company_id: "0", company_name: "All" });
          }
        });
    },

    bindCartype: async function (event_id) {
      // await this.$axios.get("Cards/GetByEventId/" + event_id).then((res) => {
      //   this.CardTypeForUpdate = res.data.result;
      // });
console.log(event_id);
      if(sessionStorage.ETID==1)
{
this.CardTypeForUpdate=[
{card_name:'SETUP - ARTIST',
  card_id:1
},
{card_name:'SETUP - EVENT',
  card_id:2
},
{card_name:'SETUP - RIL STAFF',
  card_id:3
},
{card_name:'SETUP - PLAZA',
  card_id:4
},

      ]
}
else{
this.CardTypeForUpdate=[
{card_name:'RIL - FOH',
  card_id:5
},
{card_name:'RIL - BOH',
  card_id:6
},
{card_name:'RIL - ALL AREA',
  card_id:7
},
{card_name:'ARTIST',
  card_id:8
},

{card_name:'VENDOR - FOH',
  card_id:9
},
{card_name:'VENDOR - BOH',
  card_id:10
},

      ]
}
    },

    bindStatus: async function () {
      await this.$axios.get("Status").then((res) => {
        this.Status = res.data.result;
        this.StatusForUpdate = res.data.result;
      });
    },

    bindReson: async function (StatusID) {
      await this.$axios.get("Reason/GetByStatusID/" + this.$store.state.eventInfo.EventId + "/" +  StatusID).then((res) => {
        this.ResonForUpdate = res.data.result;
      });
    },

    binddata: async function (eventid, venueid, vendorid, appid) {
      // alert("vendorid" + vendorid + "appid-" +  appid)
      this.overlay = 1;
      await this.$axios
        .post("Application/DetailsByAppType", {
          application_id: appid,
          vendor_id: vendorid,
          cType:this.SelEventtype,
        })
        .then((res) => {
          // console.log("A");
          // console.log(res.data);
          //console.log("B");
          this.cApp = res.data.result;
           //console.log(this.cApp);
          // console.log("this.cApp DATA");


//this.cApp.dob = "";
        //  this.cApp.dob = this.cApp.dob.slice(0, 10);
         


         // this.cApp.fatherName=this.cApp.fatherName
          this.overlay = 0;
          this.bindReson(this.cApp.status_id);
        });
    },

    Reload: async function (event_id, venue_id, company_id, status) {
     // alert(sessionStorage.ETID + "," + status);
     // console.log(event_id + ','  + venue_id + ',' + company_id + ',' + status +',' + this.$store.state.eventInfo.EventAdminID);
      this.loadingstatus = true;
      await this.$axios
        .get(
          "Application/GetByAppStatus/" +
            event_id +
            "/" +
            venue_id +
            "/" +
            company_id +
            "/" +
            status +
            "/" +
            this.$store.state.eventInfo.EventAdminID + "/" + sessionStorage.ETID
        )
        .then((res) => {
          this.desserts = res.data.result;
          //console.log("BIKII");
           //console.log(res.data.result);
          this.loadingstatus = false;
          this.TDselected = [];
          this.postBulkUpdate.application_id = [];
        })
        .catch()
        .finally();
    },
    New() {
      this.EntryMode = "New Vendor";
      //  this.postBody.event_name = "";
      this.CleareData();
      this.sheet = true;
    },

    btnsave: async function () {
      //this.$store.state.eventInfo.EventId, this.$store.state.eventInfo.VenueId
      //this.TDselected="";
      this.overlay = true;
      if (this.SelstatusForUpade.length <= 0) {
        this.showSnackbar("error", "Select Status !");
        this.overlay = false;
        return;
      }

      if (this.SelstatusForUpade == "3") {
        if (this.SelCardTypeForUpdate.length <= 0) {
          this.showSnackbar("error", "Select Card !");
          this.overlay = false;
          return;
        }
      }

      if (this.SelstatusForUpade != "3" && this.SelstatusForUpade != "2") {
        if (this.SelReasonForUpdate.length <= 0) {
          this.showSnackbar("error", "Select Reason !");
          this.overlay = false;
          return;
        }
      }

      //  alert(this.);

      if (this.SelstatusForUpade == "3") {
        this.StatusMessage = "Application Approved";
      } else if (this.SelstatusForUpade == "2") {
        this.StatusMessage = "Application Approved";
      } else if (this.SelstatusForUpade == "4") {
        this.StatusMessage = "Application Amended";
      } else {
        this.StatusMessage = "Application Rejected";
      }

      this.TDselected.forEach((el) => {
        this.postBulkUpdate.application_id.push(el.application_id);
      });

      this.postBulkUpdate.event_id = this.$store.state.eventInfo.EventId;
      this.postBulkUpdate.venue_id = this.$store.state.eventInfo.VenueId;
      // this.postBulkUpdate.vendor_id = this.SelCompany;
      this.postBulkUpdate.status_id = this.SelstatusForUpade;
      this.postBulkUpdate.eaid = this.$store.state.eventInfo.EventAdminID;

      if (this.SelstatusForUpade == "3") {
        this.postBulkUpdate.reason_id = 0;
        this.postBulkUpdate.card_id = this.SelCardTypeForUpdate;
      } else if (this.SelstatusForUpade == "2") {
        this.postBulkUpdate.card_id = "0";
        this.postBulkUpdate.reason_id = 0;
      } else {
        this.postBulkUpdate.card_id = "0";
        this.postBulkUpdate.reason_id = this.SelReasonForUpdate;
      }

      await this.$axios
        .put("Application/ChangeMultiAppStatus", this.postBulkUpdate)
        .then((res) => {
          if (res.data.status == "Success") {
            this.showSnackbar("success", this.StatusMessage);
            this.Reload(
              this.$store.state.eventInfo.EventId,
              this.$store.state.eventInfo.VenueId,
              this.SelCompany,
              this.Selstatus
            );
            this.overlay = false;
          }
        })
        .catch()
        .finally();
    },

    close() {
      this.sheet = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
  },
};
</script>

<style scoped>
.w-140 {
  width: 120px !important;
}
</style>
